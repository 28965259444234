var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main-box riskIdentification" },
    [
      _c(
        "el-tabs",
        {
          attrs: { type: "border-card" },
          on: { "tab-click": _vm.handleClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        _vm._l(_vm.biz_sectior, function (item, index) {
          return _c("el-tab-pane", {
            key: index,
            attrs: { label: item.dictValue, name: item.dictKey },
          })
        }),
        1
      ),
      _c(
        "div",
        { staticClass: "flex-container" },
        [
          _c("CommonTreeNew", {
            ref: "commonTreeCore",
            staticClass: "introduceTree",
            attrs: {
              treeTitle: "风险源配置",
              "node-key": "id",
              isLeafIcons: "",
              risk: "",
              defaultExpandedKeys: _vm.defaultExpandedKeys,
              defaultProps: _vm.defaultProps,
              treeData: _vm.corePersonnelTreeData,
              showCheckbox: false,
            },
            on: {
              getNodeClick: _vm.corePersonnelTreeNodeClick,
              getTreeData: _vm.commonTreeData,
            },
          }),
          _c(
            "div",
            { staticClass: "table-box" },
            [
              _c("head-layout", {
                attrs: { "head-btn-options": [], "head-title": "风险清单" },
              }),
              _c("grid-layout", {
                ref: "gridLayOut",
                staticClass: "introduceTable",
                attrs: {
                  "table-options": _vm.corePersonnelTableOption,
                  "data-total": _vm.page.total,
                  page: _vm.page,
                  "table-data": _vm.tableData,
                  "table-loading": _vm.TableLoading,
                },
                on: {
                  "page-current-change": _vm.onLoad,
                  "page-size-change": _vm.onLoad,
                  "gird-handle-select-click": _vm.selectionChange,
                },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "div",
        { staticClass: "flex-container flex-jus-e footerBtn" },
        [
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                disabled: _vm.loading,
                size: "mini",
              },
              on: { click: _vm.closeDialog },
            },
            [_vm._v("取消")]
          ),
          _c(
            "el-button",
            {
              attrs: {
                loading: _vm.loading,
                disabled: _vm.loading,
                size: "mini",
                type: "primary",
              },
              on: { click: _vm.confirm },
            },
            [_vm._v("确认")]
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }