var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      attrs: {
        title: "计划资源列表",
        visible: _vm.dialogVisible,
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.dialogVisible = $event
        },
      },
    },
    [
      _c(
        "div",
        { staticStyle: { "background-color": "#ffffff", height: "100%" } },
        [
          _c(
            "div",
            { staticClass: "searchFormBox" },
            [
              _c(
                "el-row",
                {
                  staticClass: "searchFormRow",
                  attrs: { gutter: 20, id: "gridHeadLayout" },
                },
                [
                  _c(
                    "div",
                    { staticClass: "flex-container flex-wrap-w" },
                    [
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-input", {
                            attrs: {
                              size: "mini",
                              placeholder: "请输入名称搜索",
                            },
                            model: {
                              value: _vm.taskName,
                              callback: function ($$v) {
                                _vm.taskName = $$v
                              },
                              expression: "taskName",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        "el-col",
                        { attrs: { span: 4 } },
                        [
                          _c("el-button", {
                            attrs: { icon: "el-icon-search", size: "mini" },
                            on: { click: _vm.searchFunc },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              ),
            ],
            1
          ),
          _c(
            "div",
            { staticStyle: { padding: "0 12px" } },
            [
              _c(
                "el-table",
                {
                  attrs: {
                    data: _vm.tableData,
                    border: "",
                    height: "500",
                    "row-key": "id",
                    "default-expand-all": "",
                  },
                  on: { "selection-change": _vm.handleSelectionChange },
                },
                [
                  _c("el-table-column", {
                    attrs: { type: "selection", width: "55" },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "taskCode",
                      label: "编码",
                      sortable: "",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: {
                      prop: "taskName",
                      label: "名称",
                      sortable: "",
                      width: "180",
                    },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "", label: " ", width: "60" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "taskType", label: "作业类型" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "duration", label: "原定工期" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "start", label: "计划开始" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "finish", label: "计划结束" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "actualDuration", label: "实际工期" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "actualStart", label: "实际开始" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "actualFinish", label: "实际结束" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "weight", label: "权重" },
                  }),
                  _c("el-table-column", {
                    attrs: { prop: "complete", label: "完成进度" },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "div",
            {
              staticClass: "dialog-footer",
              staticStyle: { "text-align": "right" },
              attrs: { slot: "footer" },
              slot: "footer",
            },
            [
              _c(
                "el-button",
                {
                  staticStyle: { "margin-right": "5px" },
                  on: {
                    click: function ($event) {
                      _vm.dialogVisible = false
                    },
                  },
                },
                [_vm._v("取 消")]
              ),
              _c(
                "el-button",
                {
                  attrs: { type: "primary" },
                  on: { click: _vm.handleConfirm },
                },
                [_vm._v("确 定")]
              ),
            ],
            1
          ),
        ]
      ),
    ]
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }