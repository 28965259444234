// 1.施工内容 添加引用功能(数据源由张强提供)
// 添加一个安全记录日期
// 2.有输入框的复选框项放最后一行，单独一行
// 3.施工单位:当前登录人的单位
// 4.安全检查：添加引用功能，风险辨识库（汤勇提供数据接口）
// 5.专业许可证（关联本单位的作业票 http://123.60.160.227/jobSlip/jobTicketList/index）作业票类型（作业票编号）链接作业票详情
// 6.应急电话（选人组件）
// 7.班前会（自动关联本单位的班前会）会议名称（会议编号）

const danger_checkList = [
  {
    dictKey:'d1',
    dictValue:'物体打击'
  },
  {
    dictKey:'d2',
    dictValue:'车辆伤害'
  },
  {
    dictKey:'d3',
    dictValue:'机械伤害'
  },
  {
    dictKey:'d4',
    dictValue:'起重伤害'
  },
  {
    dictKey:'d5',
    dictValue:'触电'
  },
  {
    dictKey:'d6',
    dictValue:'淹溺'
  },
  {
    dictKey:'d7',
    dictValue:'灼烫'
  },
  {
    dictKey:'d8',
    dictValue:'火灾'
  },
  {
    dictKey:'d9',
    dictValue:'高处坠落'
  },
  {
    dictKey:'d10',
    dictValue:'坍塌'
  },
  {
    dictKey:'d11',
    dictValue:'放炮',
  },
  {
    dictKey:'d12',
    dictValue:'火药爆炸'
  },
  {
    dictKey:'d13',
    dictValue:'容器爆炸'
  },
  {
    dictKey:'d14',
    dictValue:'中毒和窒息'
  },
  {
    dictKey:'d15',
    dictValue:'交叉作业'
  },
  {
    dictKey:'d16',
    dictValue:'其它爆炸'
  },
  {
    dictKey:'d17',
    dictValue:'其它伤害'
  }
]

const protect_checkList =
[
  {
  dictKey:'tbfh',
  dictValue:'头部防护',
  chidren:[{
    dictKey:'d1',
    dictValue:'安全帽'
  },
  {
    dictKey:'d2',
    dictValue:'安全眼镜'
  },
  {
    dictKey:'d3',
    dictValue:'染色镜片'
  },
  {
    dictKey:'d4',
    dictValue:'护目镜'
  },
  {
    dictKey:'d5',
    dictValue:'面罩'
  },
  {
    dictKey:'d6',
    dictValue:'焊工罩'
  }]
},
{
  dictKey:'zbfh',
  dictValue:'足部防护',
  chidren:[{
    dictKey:'d1',
    dictValue:'安全钢头鞋或靴'
  },
  {
    dictKey:'d2',
    dictValue:'化学品防护安全鞋或靴'
  },
  {
    dictKey:'d3',
    dictValue:'靴'
  }]
},
{
  dictKey:'st',
  dictValue:'手套',
  chidren:[{
    dictKey:'d1',
    dictValue:'普通用'
  },
  {
    dictKey:'d2',
    dictValue:'化学品防护'
  },
  {
    dictKey:'d3',
    dictValue:'灼伤防护'
  },
  {
    dictKey:'d4',
    dictValue:'割伤防护'
  },
  {
    dictKey:'d5',
    dictValue:'其它',
    showInput:true
  }]
},
{
  dictKey:'hxfh',
  dictValue:'呼吸防护',
  chidren:[{
    dictKey:'d1',
    dictValue:'烟雾'
  },
  {
    dictKey:'d2',
    dictValue:'化学品'
  },
  {
    dictKey:'d3',
    dictValue:'类型',
    showInput:true
  }]
},
{
  dictKey:'fhf',
  dictValue:'防护服',
  chidren:[{
    dictKey:'d1',
    dictValue:'普通'
  },
  {
    dictKey:'d2',
    dictValue:'防火'
  },
  {
    dictKey:'d3',
    dictValue:'防水'
  },
  {
    dictKey:'d4',
    dictValue:'防化学品'
  },
  {
    dictKey:'d5',
    dictValue:'处理',
    showInput:true
  }]
},
{
  dictKey:'sgpt',
  dictValue:'施工平台',
  chidren:[{
    dictKey:'d1',
    dictValue:'移动式升降平台'
  },
  {
    dictKey:'d2',
    dictValue:'脚手架'
  },
  {
    dictKey:'d3',
    dictValue:'楼梯'
  }]
},
{
  dictKey:'fz',
  dictValue:'防坠',
  chidren:[{
    dictKey:'d1',
    dictValue:'安全带'
  },
  {
    dictKey:'d2',
    dictValue:'安全带鞋带'
  },
  {
    dictKey:'d3',
    dictValue:'攀登索'
  },
  {
    dictKey:'d4',
    dictValue:'垂直攀登设备'
  },
  {
    dictKey:'d5',
    dictValue:'其它',
    showInput:true,
  }]
},
{
  dictKey:'ryjk',
  dictValue:'人员监控',
  chidren:[{
    dictKey:'d1',
    dictValue:'硫化氢'
  },
  {
    dictKey:'d2',
    dictValue:'氨气'
  },
  {
    dictKey:'d3',
    dictValue:'多功能'
  },
  {
    dictKey:'d4',
    dictValue:'其它',
    showInput:true
  }]
},
{
  dictKey:'lsbs',
  dictValue:'临时标识',
  chidren:[{
    dictKey:'d1',
    dictValue:'警示标识'
  },
  {
    dictKey:'d2',
    dictValue:'护栏'
  },
  {
    dictKey:'d3',
    dictValue:'警示带'
  }]
}]

const protection_checkList = [
  {
    dictKey:'d1',
    dictValue:'清理工具箱'
  },
  {
    dictKey:'d2',
    dictValue:'不适用'
  }
]
const environment_checkList = [{
  hxp:{

  }
}]

const waste_checkList = [

  {
    dictKey:'d1',
    dictValue:'废弃物分离'
  },
  {
    dictKey:'d2',
    dictValue:'垃圾箱'
  },
  {
    dictKey:'d3',
    dictValue:'垃圾箱标签'
  },
  {
    dictKey:'d4',
    dictValue:'不适用'
  }
]
export default {
  danger_checkList,
  protect_checkList,
  protection_checkList,
  waste_checkList
}