<template>
  <el-dialog title="计划资源列表" :visible.sync="dialogVisible" width="70%">
    <div style="background-color: #ffffff; height: 100%">
      <div class="searchFormBox">
        <el-row :gutter="20" class="searchFormRow" id="gridHeadLayout">
          <div class="flex-container flex-wrap-w">
            <el-col :span="4">
              <el-input v-model="taskName" size="mini" placeholder="请输入名称搜索"></el-input>
            </el-col>
            <el-col :span="4">
              <el-button icon="el-icon-search" size="mini" @click="searchFunc"></el-button>
            </el-col>
          </div>
        </el-row>
      </div>
      <div style="padding: 0 12px;">
        <el-table :data="tableData" border height="500" @selection-change="handleSelectionChange" row-key="id" default-expand-all>
          <el-table-column type="selection" width="55"></el-table-column>
          <el-table-column prop="taskCode" label="编码" sortable width="180"></el-table-column>
          <el-table-column prop="taskName" label="名称" sortable width="180"></el-table-column>
          <el-table-column prop="" label=" " width="60"></el-table-column>
          <el-table-column prop="taskType" label="作业类型"></el-table-column>
          <el-table-column prop="duration" label="原定工期"></el-table-column>
          <el-table-column prop="start" label="计划开始"></el-table-column>
          <el-table-column prop="finish" label="计划结束"></el-table-column>
          <el-table-column prop="actualDuration" label="实际工期"></el-table-column>
          <el-table-column prop="actualStart" label="实际开始"></el-table-column>
          <el-table-column prop="actualFinish" label="实际结束"></el-table-column>
          <el-table-column prop="weight" label="权重"></el-table-column>
          <el-table-column prop="complete" label="完成进度"></el-table-column>
        </el-table>
      </div>
      <div slot="footer" class="dialog-footer" style="text-align: right;">
        <el-button @click="dialogVisible = false" style="margin-right: 5px;">取 消</el-button>
        <el-button type="primary" @click="handleConfirm">确 定</el-button>
      </div>
    </div>

  </el-dialog>
</template>

<script>

import { mapGetters } from "vuex";

import { eventList } from "@/api/constructionScheduling/index";
export default {
  name: 'beforeclass',
  props: {
    prjId: {
      type: Number,
      default: undefined
    }
  },
  components: {
  },
  data() {
    return {
      dialogVisible: false,
      tableData: [],
      selected: [],
      tableLoading: true,
    };
  },
  computed: {
    ...mapGetters(["userInfo"]),
  },
  mounted() {
  },
  methods: {
    init() {
      let that = this;
      this.dialogVisible = true;
      this.tableLoading = true;
      let prjId = this.prjId;
      let taskName = this.taskName;

      let params = { prjId, taskName };
      eventList(params).then((res) => {
        console.log(res)
        if (res.data.code == 200) {
          that.tableLoading = false;
          var data = res.data.data;
          that.tableData = data;
        }
      });
    },
    searchFunc() {
      this.init();
    },
    handleSelectionChange(data) {
      this.selected = data;
    },
    handleConfirm() {
      let selected = this.selected;
      this.$emit('confirm', selected);
      this.dialogVisible = false;
    }
  }
};
</script>

<style lang="scss" scoped>
.head-layout {
  background-color: #ffffff;
  border-bottom: 1px solid #cccccc;
  height: 46px;
}

.headLabel {
  font-size: 14px;
  margin-left: 6px;
  white-space: nowrap;
  text-overflow: ellipsis;
}

.headBtn {
  margin-right: 6px;
}

.searchFormRow {
  margin-left: -2.5px;
  margin-right: -2.5px;
}

.searchFormRowitem {
  padding-left: 2.5px;
  padding-right: 2.5px;
}
</style>
