<template>
  <div class="add-form">
    <head-layout @head-save="headSave" @head-complete="headComplete" @head-cancel="headCancel"
      :head-btn-options="headBtnOptions" head-title="基础信息">
    </head-layout>
    <div class="block">
      <el-form ref="baseInfo" :model="baseInfo" label-width="120px" :rules="baseRules" :disabled="type == 'view'">
        <el-row>
          <el-col :span="8">
            <el-form-item label="编号" prop="workCode">
              <el-input v-model="baseInfo.workCode" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="项目名称" prop="organizationName">
              <el-input v-model="baseInfo.organizationName" disabled></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="发起日期" prop="sendDate">
              <el-date-picker v-model="baseInfo.sendDate" value-format="yyyy-MM-dd" disabled type="date"
                placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="工作记录日期" prop="recordDate">
              <el-date-picker v-model="baseInfo.recordDate" value-format="yyyy-MM-dd" type="date" placeholder="选择日期">
              </el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <!-- tableHeadBtnOptions @head-add="tableAdd('wtSecurityPreventionList')"  workSafetyConstructionContentList -->
    <head-layout head-title="施工内容" :head-btn-options="type != 'view' ? securityContentOption : []"
      @head-add="tableQutoeList()" @head-addLine="tableAdd('workSafetyCheckList')"></head-layout>
    <div class="block">
      <div class="el-form-title">施工单位：{{ baseInfo.unitName }}</div>
      <el-table :data="workSafetyConstructionContentList" border size="medium" style="width: 100%">
        <el-table-column label="序号" type="index" align="center" width="50">
        </el-table-column>
        <el-table-column prop="address" align="center" label="工作地点">
          <template slot-scope="scope">
            <el-input v-model="scope.row.address" placeholder="请输入工作地点" type="textarea" :disabled="type == 'view'"
              :autosize="{ minRows: 1, maxRows: 6 }" maxlength="255" show-word-limit></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="content" align="center" label="工作内容">
          <template slot-scope="scope">
            <el-input v-model="scope.row.content" placeholder="请输入工作内容" type="textarea" :disabled="type == 'view'"
              :autosize="{ minRows: 1, maxRows: 6 }" maxlength="255" show-word-limit></el-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <!-- <el-button v-if="workSafetyConstructionContentList.length - 1 == scope.$index" :disabled="type == 'view'"
              @click="tableAdd('workSafetyConstructionContentList')" type="text" size="small">新增</el-button> -->
            <el-button
              @click="handleDelRow(scope.$index, scope.row, 'workSafetyConstructionContentList')" type="text"
              :disabled="type == 'view'" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <head-layout head-title="危险确认" :head-btn-options="[]"></head-layout>
    <div class="block">
      <div class="dangerCheckList">
        <el-checkbox-group v-model="dangerCheckList" :disabled="type == 'view'">
          <el-row>
            <el-col :span="item.dictKey.includes('_input') ? 24 : 6" v-for="item in safework_dangerType"
              :key="item.dictKey"
              :class="item.dictKey.includes('_input') ? 'el-col-checkList flex-checkList' : 'el-col-checkList'">
              <el-checkbox :label="item.dictValue">
              </el-checkbox>
              <el-input type="textarea" :disabled="!dangerCheckList.includes(item.dictValue) || type == 'view'"
                v-if="item.dictKey.includes('_input')" v-model="dangerCheckInput[item.dictKey]"
                :placeholder="'请输入' + item.dictValue" :autosize="{ minRows: 1, maxRows: 6 }"></el-input>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </div>
    </div>
    <!-- tableHeadBtnOptions @head-add="tableAdd('wtSecurityCheckList')" -->
    <head-layout head-title="施工准备" :head-btn-options="[]"></head-layout>
    <div class="block constructionForm">
      <el-form ref="constructionForm" :model="constructionForm" label-width="130px" :disabled="type == 'view'">
        <el-row>
          <el-col :span="8">
            <el-form-item label="是否需要增加照明" prop="sfZjzm">
              <el-radio v-model="constructionForm.sfZjzm" :label="1">是</el-radio>
              <el-radio v-model="constructionForm.sfZjzm" :label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="layout-title">安全检查</div>
      <!-- <el-button size="mini" type="primary" @click="tableQuote('')">引用风险库 wtSecurityCheckList
      </el-button>  -->
      <head-layout v-if="type != 'view'" head-title="" :head-btn-options="wtSecurityOption"
        @head-add="tableQuote" @head-addLine="tableAdd('workSafetyCheckList')"></head-layout>
      <el-table :data="workSafetyCheckList" border size="medium" style="width: 100%" :disabled="type == 'view'">
        <el-table-column label="序号" type="index" align="center" width="50">
        </el-table-column>
        <el-table-column prop="riskName" align="center" label="危险源描述">
          <template slot-scope="scope">
            <el-input v-model="scope.row.riskName" placeholder="请输入危险源描述" type="textarea" :disabled="type == 'view'"
              :autosize="{ minRows: 1, maxRows: 6 }" show-word-limit></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="treatEngineer" align="center" label="工程技术">
          <template slot-scope="scope">
            <el-input v-model="scope.row.treatEngineer" placeholder="请输入工程技术" type="textarea" :disabled="type == 'view'"
              :autosize="{ minRows: 1, maxRows: 6 }" show-word-limit></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="treatManagment" align="center" label="管控措施">
          <template slot-scope="scope">
            <el-input v-model="scope.row.treatManagment" placeholder="请输入管控措施" type="textarea"
              :disabled="type == 'view'" :autosize="{ minRows: 1, maxRows: 6 }" show-word-limit></el-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <!-- <el-button v-if="workSafetyCheckList.length - 1 == scope.$index" @click="tableAdd('workSafetyCheckList')"
              :disabled="type == 'view'" type="text" size="small">新增</el-button> -->
            <el-button 
              @click="handleDelRow(scope.$index, scope.row, 'workSafetyCheckList')" type="text"
              :disabled="type == 'view'" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
      <el-form style="margin-top: 10px;" ref="constructionForm" :model="constructionForm" label-width="100px">
        <el-row>
          <el-col :span="2">
            <el-button style="margin-bottom:10px" size="small" @click="jobticketShow" type="primary"
              :disabled="type == 'view'">关联专业许可证</el-button>
          </el-col>
          <el-col :span="22">
            <!-- <el-form-item label="专业许可证" prop="jobTicketList"> -->
              <div class="link-list">
                <div class="link-item" v-for="(item, index) in jobTicketList" :key="item.id">
                  <div class="text" @click="toJobTicketDetail(item)">{{
                    `${item.$ticketType}(${item.ticketCode})`
                  }}</div>
                  <i v-if="type != 'view'" class="el-icon-remove" @click="removeJobTicketList(index)"></i>
                </div>
              </div>
            <!-- </el-form-item> -->
          </el-col>
          <el-col :span="24">
            <el-form-item label="施工方案" prop="fileList">
              <el-upload action="/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform"
                :disabled="type == 'view'" :headers="headers" :file-list="fileList" file="file"
                :on-success="handleFileCoverSuccess" :on-preview="handleFileCoverPreview" :on-remove="handleRemove"
                accept=".doc,.docx,.pdf,.png,.jpg,.zip" :limit="9">
                <el-button size="small" type="primary" :disabled="type == 'view'">点击上传</el-button>
              </el-upload>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <head-layout head-title="需要的防护设备" :head-btn-options="[]"></head-layout>
    <div class="block">
      <div class="protect-checkList" v-for="item in safework_protectType" :key="item.dictKey">
        <div class="title">{{ item.dictValue + ':' }}</div>
        <el-checkbox-group v-model="protectMap[item.dictKey]" :disabled="type == 'view'">
          <el-row>
            <el-col :span="chiItem.dictKey.includes('_input') ? 24 : 6" v-for="chiItem in item.children"
              :key="chiItem.dictKey"
              :class="chiItem.dictKey.includes('_input') ? 'el-col-checkList flex-checkList' : 'el-col-checkList'">
              <el-checkbox :label="chiItem.dictValue">
              </el-checkbox>
              <el-input type="textarea" v-if="chiItem.dictKey.includes('_input')"
                :disabled="!protectMap[item.dictKey].includes(chiItem.dictValue) || type == 'view'"
                v-model="protectInput[item.dictKey][chiItem.dictKey]" :placeholder="'请输入' + chiItem.dictValue"
                :autosize="{ minRows: 1, maxRows: 6 }"></el-input>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </div>
    </div>
    <!-- tableHeadBtnOptions  @head-add="tableAdd('wtMeetCheckList')"-->
    <head-layout head-title="应急准备" :head-btn-options="[]"></head-layout>
    <div class="block meetForm">
      <el-form ref="meetForm" :model="meetForm" label-width="240px" :disabled="type == 'view'">
        <el-row>
          <el-col :span="24">
            <el-form-item label="有消防设备，状态如何，近期检查？" prop="sfXfsb">
              <el-radio v-model="meetForm.sfXfsb" :label="1">是</el-radio>
              <el-radio v-model="meetForm.sfXfsb" :label="0">否</el-radio>
            </el-form-item>
          </el-col>
          <el-col :span="24">
            <el-form-item label="有应急设备，状态如何，近期检查？" prop="sfYjsb">
              <el-radio v-model="meetForm.sfYjsb" :label="1">是</el-radio>
              <el-radio v-model="meetForm.sfYjsb" :label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>
      <div class="el-form-title">
        若有火情，事故或环境问题，拨打应急电话
      </div>
      <!--  wtMeetCheckList -->
      <el-table :data="workSafetyContactsList" border size="medium" style="width: 100%" :disabled="type == 'view'">
        <el-table-column label="序号" type="index" align="center" width="50">
        </el-table-column>
        <el-table-column prop="deptName" align="center" label="单位名称">
          <template slot-scope="scope">
            <el-input v-model="scope.row.deptName" placeholder="请输入单位名称" :disabled="type == 'view'"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="post" align="center" label="职位">
          <template slot-scope="scope">
            <el-input v-model="scope.row.post" placeholder="请输入职位" :disabled="type == 'view'"></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="name" align="center" label="姓名">
          <template slot-scope="scope">
            <el-input v-model="scope.row.name" placeholder="请输入姓名" :disabled="type == 'view'"><el-button
                @click="showMultipShow(scope.$index, 'workSafetyContactsList')" slot="append" :disabled="type == 'view'"
                icon="el-icon-search"></el-button></el-input>
          </template>
        </el-table-column>
        <el-table-column prop="phone" align="center" label="电话号码">
          <template slot-scope="scope">
            <el-input v-model="scope.row.phone" placeholder="请输入电话号码" :disabled="type == 'view'"></el-input>
          </template>
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button v-if="workSafetyContactsList.length - 1 == scope.$index"
              @click="tableAdd('workSafetyContactsList')" type="text" size="small"
              :disabled="type == 'view'">新增</el-button>
            <el-button v-if="workSafetyContactsList.length > 1"
              @click="handleDelRow(scope.$index, scope.row, 'workSafetyContactsList')" type="text"
              :disabled="type == 'view'" size="small">删除</el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>

    <head-layout head-title="环境保护" :head-btn-options="[]"></head-layout>
    <div class="block">
      <el-form ref="protectionForm" :model="protectionForm" label-width="100px" :disabled="type == 'view'">
        <el-row>
          <el-col :span="6">
            <el-form-item label="化学品污染？" prop="radio">
              <el-radio v-model="protectionForm.sfHxpwr" :label="1">是</el-radio>
              <el-radio v-model="protectionForm.sfHxpwr" :label="0">否</el-radio>
            </el-form-item>
          </el-col>
        </el-row>
      </el-form>

      <div class="protection-checkList" v-for="item in safework_environmentalType" :key="item.dictKey">
        <div class="title">{{ item.dictValue + ':' }}</div>
        <el-checkbox-group v-model="safework_environmentalMap[item.dictKey]" :disabled="type == 'view'">
          <el-row>
            <el-col :span="chiItem.dictKey.includes('_input') ? 24 : 6" v-for="chiItem in item.children"
              :key="chiItem.dictKey"
              :class="chiItem.dictKey.includes('_input') ? 'el-col-checkList flex-checkList' : 'el-col-checkList'">
              <el-checkbox :label="chiItem.dictValue" :value="chiItem.dictKey">
              </el-checkbox>
              <el-input type="textarea" v-if="chiItem.dictKey.includes('_input')"
                :disabled="!safework_environmentalMap[item.dictKey].includes(chiItem.dictValue) || type == 'view'"
                v-model="safework_environmentalInput[item.dictKey][chiItem.dictKey]"
                :placeholder="'请输入' + chiItem.dictValue" :autosize="{ minRows: 1, maxRows: 6 }"></el-input>
            </el-col>
          </el-row>
        </el-checkbox-group>
      </div>
    </div>

    <head-layout head-title="班前会" :head-btn-options="[]"></head-layout>
    <div class="block">
      <div class="link-list">
        <div class="link-item" v-for="(item, index) in meetList" :key="item.id">
          <div class="text" @click="toMeetList(item)">{{
            `${item.title}(${item.serialNumber})`
          }}</div>
        </div>
      </div>
    </div>

    <el-dialog :visible.sync="imageVisible" width="60%" :append-to-body="true">
      <img style="margin-top:20px ; width: 100%; height: auto; display: block; " :src="imageUrl" alt="">
    </el-dialog>
    <el-dialog title="人员选择" :visible.sync="MultipShow" width="80%" top="8vh" v-if="MultipShow">
      <ProjectSelectUser @closeDia="MultipShow = false" v-if="MultipShow"
        :treeParams="{ parentId: baseInfo.organizationId }" @select-data="getUser"></ProjectSelectUser>
    </el-dialog>
    <el-dialog title="风险辨识标准库" :visible.sync="riskShow" width="80%" top="8vh" v-if="riskShow">
      <RiskIdentification @closeDia="riskShow = false" v-if="riskShow" @select-data="getRiskData"></RiskIdentification>
    </el-dialog>
    <!--关键的其他特殊作业及安全作业票号-->
    <common-dialog dialogTitle="作业票清单" v-if="jobTicketDialogShow" width="60%" @confirm="getJobTicketList"
      @cancel="jobTicketDialogShow = false">
      <jobTicketDialog ref="jobTicketDialog" :ticketCode="ticketCode" isMultiple>
      </jobTicketDialog>
    </common-dialog>
    <process-user-dialog ref="processUser" @getUserSelection="handleUserSelection"></process-user-dialog>
    <constructionList :prjId="baseInfo.organizationId" :unitId="baseInfo.unitId"  ref="listForm" @confirm="handleListForm"></constructionList>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import HeadLayout from "@/views/components/layout/head-layout";
import GridLayout from "@/views/components/layout/grid-layout";
import mapConfig from "./config.js";
import { getToken } from "@/util/auth";
import { downloadFileBlob } from "@/util/util";
import ProcessUserDialog from "@/views/business/components/processUserDialog.vue";
import { dateFormat } from "@/util/date";
import ProjectSelectUser from "@/views/components/UserDeptDialog/projectSelectUser";
import RiskIdentification from "@/views/business/safetyTtandard/riskIdentification/dialog";
import jobTicketDialog from "@/views/business/jobSlip/jobTicketList/jobTicketDialog";
import * as API from "@/api/safeworkInspection/index";
import { getDictionaryTreeCode } from "@/api/system/dictbiz";
import CommonDialog from "@/components/CommonDialog";
import { templateCode } from "@/api/workTicket";
import exForm from "@/views/plugin/workflow/mixins/ex-form";
import constructionList from "@/views/constructionScheduling/list";
import {
  ticketType,
} from "@/api/workTicket";
export default {
  components: {
    GridLayout,
    HeadLayout,
    ProjectSelectUser,
    jobTicketDialog,
    RiskIdentification,
    constructionList,
    CommonDialog,
    ProcessUserDialog
  },
  computed: {
    headers: function () {
      return { "Sinoma-Auth": getToken() };
    },
    ...mapGetters(["userInfo"]),
    headBtnOptions () {
      let buttonBtn = [];
      if (this.type !== "view") {
        buttonBtn.push({
          label: "保存",
          emit: "head-save",
          type: "button",
          icon: "",
          btnOptType: "save",
        });
        buttonBtn.push({
          label: "提交",
          emit: "head-complete",
          type: "button",
          icon: "",
        });
      }
      buttonBtn.push({
        label: "取消",
        emit: "head-cancel",
        type: "button",
        icon: "",
        btnOptType: "cancel",
      });
      return buttonBtn;
    },
  },
  mixins: [exForm],
  data () {
    return {
      imageVisible: false,
      MultipShow: false,
      riskShow: false,
      jobTicketDialogShow: false,
      baseInfo: {
        workCode: '',
        organizationName: '',
        sendDate: '',
        recordDate: '',
        unitId: '',
        unitName: ''
      },
      dangerCheckInput: {},
      protectMap: {
      },
      protectInput: {
      },
      fileList: [],
      fileLists: [],
      limitCountImg: 9,
      protectionForm: { sfHxpwr: 1 },
      wasteForm: {},
      constructionForm: { sfZjzm: 1 },
      meetForm: { sfYjsb: 1, sfXfsb: 1 },
      protectionCheckList: [],
      workSafetyConstructionContentList: [{}],
      workSafetyCheckList: [{}],
      workSafetyContactsList: [{}],
      dangerCheckList: [],
      wasteCheckList: [],
      tableHeadBtnOptions: [{
        label: "新增",
        emit: "head-add",
        type: "button",
        icon: "",
        btnOptType: "add",
      }],
      securityContentOption: [
        {
          label: "引用",
          emit: "head-add",
          type: "button",
          icon: "",
        },
        {
          label: "新增一行",
          emit: "head-addLine",
          type: "button",
          icon: "",
        }
      ],
      wtSecurityOption: [{
        label: "引用风险库",
        emit: "head-add",
        type: "button",
        icon: "",
      },
      {
          label: "新增一行",
          emit: "head-addLine",
          type: "button",
          icon: "",
      }],
      securityContentShow: false,
      danger_checkList: mapConfig.danger_checkList,
      protect_checkList: mapConfig.protect_checkList,
      protection_checkList: mapConfig.protection_checkList,
      waste_checkList: mapConfig.waste_checkList,
      organizationId: '',
      workSafetyContactsListIndex: 0,
      type: 'add',
      formId: '',
      safework_dangerType: [],
      safework_environmentalType: [],
      safework_environmentalMap: {},
      safework_environmentalInput: {},
      safework_protectType: [],
      baseRules: {
        recordDate: [
          { required: true, message: '请选择工作记录日期', trigger: 'blur' },
        ]
      },
      detailInfo: {},
      workTicketJson: [],
      meetList: [],
      ticketCode: '',
      jobTicketList: [],
      formProcess: {
        id: '',
        processDefKey: 'worksafety_process',
        // processId: '',
        formUrl: '/safeworkInspection/add',
        tokenUrl: '/api/sinoma-hse-prj/worksafety/approve'
      },
      formInfo: {},
      headSaveTimer: null,
      headCompleteTimer: null,
      kictectTypeList: [],
      router_dict: {
        gkzy: {
          kictectIcon: require("@/assets/images/kicket/gkzy1.png"),
          path: `/jobSlip/jobTicketList/heightWork`,
        },
        dtzy: {
          kictectIcon: require("@/assets/images/kicket/dtzy1.png"),
          path: `/jobSlip/jobTicketList/wtBaseDirt`,
        },
        dhzy: {
          kictectIcon: require("@/assets/images/kicket/dhzy1.png"),
          path: `/jobSlip/jobTicketList/hotWork`,
        },
        dzzy: {
          kictectIcon: require("@/assets/images/kicket/dzzy1.png"),
          path: `/jobSlip/jobTicketList/hoistingWork`,
        },
        yxkj: {
          kictectIcon: require("@/assets/images/kicket/sxkjzy1.png"),
          path: `/jobSlip/jobTicketList/limitSpace`,
        },
        lsyd: {
          kictectIcon: require("@/assets/images/kicket/lsydzy1.png"),
          path: `/jobSlip/jobTicketList/electricWork`,
        },
        dlzy: {
          kictectIcon: require("@/assets/images/kicket/dlzy1.png"),
          path: `/jobSlip/jobTicketList/openCircuitWork`,
        },
        mbcd: {
          kictectIcon: require("@/assets/images/kicket/mbcdzy1.png"),
          path: `/jobSlip/jobTicketList/wtBaseBpp`,
        },
        bpzy: {
          kictectIcon: require("@/assets/images/kicket/tdzy1.png"),
          path: `/jobSlip/jobTicketList/explodeWork`,
        },
        qtzy: {
          kictectIcon: require("@/assets/images/kicket/sdzy1.png"),
          path: `/jobSlip/jobTicketList/otherWork`,
        }
      },
    }
  },
  created () {
     this.getDic('safework_dangerType');
     this.getDic('safework_environmentalType');
     this.getDic('safework_protectType');
    // this.getProcess('worksafety_process');
     this.getTemplateCode();
     this.getTicketList();
  },
  beforeDestroy () {
    clearTimeout(this.headCompleteTimer);
    clearTimeout(this.headSaveTimer);
  },
  mounted () {
    console.log(this.$route.query.doned,this.userInfo,'this.userInfo');
    let doned = this.$route.query.doned || 0;
    let type = doned == 1 ? 'view' : this.$route.query.type;
    this.type = type || 'add'
    if (this.type == 'add') {
      this.getCode();
      this.baseInfo.sendDate = dateFormat(new Date(), 'yyyy-MM-dd');
      this.baseInfo.recordDate = dateFormat(new Date(new Date().getTime() + 24 * 60 * 60 * 1000), 'yyyy-MM-dd');
      let organizationName = this.$route.query.deptName || this.userInfo.company_name || this.userInfo.deptName || this.userInfo.dept_name
      this.organizationId = this.$route.query.deptId || this.userInfo.deptId;
      this.$set(this.baseInfo, 'organizationName', organizationName)
      this.$set(this.baseInfo, 'unitName',this.userInfo.dept_name)
      this.baseInfo.unitId = this.userInfo.dept_id
      this.baseInfo.organizationId = this.organizationId
    } else {
      this.formId = this.$route.query.id
      this.getDetail();
    }
  },
  methods: {
    handleUserSelection (userIds) {
      this.assignee = userIds;
      // this.rowRelease(this.releaseRow);
      this.handleRelease();
    },
    // 发布
    handleRelease () {
      let data = {
        deptId: this.baseInfo.organizationId,
        assignee: this.assignee,
        ...this.formProcess
      };
      this.handleStartProcess(data, this.baseInfo.organizationId).then((processRes) => {
        this.formInfo.processInstanceId = processRes.data.data;
        this.formInfo.status = 2;
        API.addUpdate(this.formInfo);
        this.$message.success(
          this.$t("cip.cmn.msg.success.operateSuccess")
        );
        this.$router.$avueRouter.closeTag();
        this.$router.push("/business/safeworkInspection/index");
        this.$loading().close();
      });
    },
    // 关联作业票
    getJobTicketList () {
      let jobTicketList = this.jobTicketList.concat(this.$refs.jobTicketDialog.confirm());
      this.jobTicketList = jobTicketList.reduce((pre, current) => {
        const duplicate = pre.find(item => item.id === current.id);
        if (!duplicate) {
          return pre.concat([current]);
        }
        return pre;
      }, []);
      this.jobTicketDialogShow = false;
    },
    removeJobTicketList (index) {
      this.jobTicketList.splice(index, 1);
    },
    handleListForm (data) {
      let arr = []
      data.map(item => {
        arr.push({
          address: item.areaName,
          content: item.taskName
        })
      });
      this.workSafetyConstructionContentList = this.workSafetyConstructionContentList.concat(arr);
      console.log(data, 'handleListForm')
    },
    getTicketList () {
      ticketType("work_permit_type").then((res) => {
        if (res.data.code == 200) {
          let kictectTypeList = res.data.data.map((item) => {
            return {
              ...item,
              kictectIcon: this.router_dict[item.dictKey].kictectIcon,
              routeObj: {
                path: this.router_dict[item.dictKey].path,
                query: {
                  jobTicketType: item.dictKey,
                  type: 'add'
                },
              },
            }
          });
          this.kictectTypeList = kictectTypeList;
        }
      });
    },
    toJobTicketDetail (row) {
      if (row.statuSort == 2) {
        //线下
        this.$router.push({
          path: "/jobSlip/jobTicketList/offline",
          query: {
            type: 'view',
            ticketCode: row.ticketCode,
            jobTicketType: row.ticketType,
            id: row.id,
            businessStatus: row.businessStatus,
            characters: row.characters,
            processInstanceId: row.processInstanceId,
          }
        })
      } else {
        let item = this.kictectTypeList.find(e => e.dictKey == row.ticketType)
        item.routeObj.query.ticketCode = row.ticketCode
        item.routeObj.query.id = row.id
        item.routeObj.query.type = 'view'
        item.routeObj.query.jobTicketType = row.ticketType
        item.routeObj.query.businessStatus = row.businessStatus
        item.routeObj.query.characters = row.characters
        item.routeObj.query.processInstanceId = row.processInstanceId
        this.$router.push(item.routeObj)
      }
    },
    getMeetList () {
      API.getMeetList({ unitId: this.detailInfo.unitId,organizationId:this.detailInfo.organizationId, recordDate: this.baseInfo.recordDate }).then(res => {
        this.meetList = res.data.data;
      })
    },
    // 获取作业票编号
    getTemplateCode () {
      templateCode().then((res) => {
        if (res.data.code == 200) {
          this.ticketCode = res.data.data;
        }
      })
    },
    // 获取字典 危险确认模块 safework_dangerType  环境保护 safework_environmentalType  防护设备safework_protectType
    getDic (code) {
       getDictionaryTreeCode(code).then(res => {
        console.log('getDictionaryTreeCode')
        this[code] = res.data.data;
        if (code == 'safework_protectType') {
          this[code].map(item => {
            this.$set(this.protectMap, item.dictKey, [])
            this.$set(this.protectInput, item.dictKey, {})
            item.children.map(chiItem => {
              if (chiItem.dictKey.includes('_input')) {
                this.$set(this.protectInput[item.dictKey], chiItem.dictKey, '')
              }
            })
          })
        } else if (code == 'safework_environmentalType') {
          this[code].map(item => {
            this.$set(this.safework_environmentalMap, item.dictKey, []);
            this.$set(this.safework_environmentalInput, item.dictKey, {})
            item.children.map(chiItem => {
              if (chiItem.dictKey.includes('_input')) {
                this.$set(this.safework_environmentalInput[item.dictKey], chiItem.dictKey, '')
              }
            })
          })
        } else if (code == 'safework_dangerType') {
          this[code].map(item => {
            if (item.dictKey.includes('_input')) {
              this.$set(this.dangerCheckInput, item.dictKey, '')
            }
          })
        }
      })
    },
    // 获取code
    getCode () {
      API.getCode().then(res => {
        if (res.data.code == 200) {
          this.$set(this.baseInfo, 'workCode', res.data.data);
        }
      })
    },
    // 关联作业票
    jobticketShow () {
      this.jobTicketDialogShow = true;
    },
    // 引用风险库
    tableQuote () {
      this.riskShow = true
    },
    getRiskData (data) {
      this.riskShow = false
      console.log(data)
      let arr = []
      data.map(item => {
        arr.push({
          riskName: item.riskName,
          treatEngineer: item.treatEngineer,
          treatManagment: item.treatManagment
        })
      })
      this.workSafetyCheckList = this.workSafetyCheckList.concat(arr);
    },
    getUser (data) {
      let personInfo = {
        phone: data.phone,
        name: data.userName,
        post: data.stPostName,
        deptName: data.deptName
      }
      this.$set(this.workSafetyContactsList, this.workSafetyContactsListIndex, personInfo)
      this.MultipShow = false;
    },
    showMultipShow (index, dataName) {
      this.workSafetyContactsListIndex = index;
      this.MultipShow = true;
    },
    handleRemove (file, fileList) {
      this.fileLists = fileList.map(item => {
        let file = item.response.data;
        return {
          name: file.name,
          originalName: file.originalName,
          link: file.link,
          attachId: file.attachId
        }
      })
    },
    handleFileCoverSuccess (response) {
      let { name, link, originalName } = response.data;
      this.fileLists.push({
        name: originalName,
        link: link,
        originalName: name,
      })
      console.log(this.fileLists);
    },
    handleFileCoverPreview (file) {
      const fileExtension = file.name?.slice(((file.name.lastIndexOf(".") - 1) >>> 0) + 2);
      let url = file.url;
      if (!file.url) {
        url = file.response.data.link
      }
      if (["docx", "doc", "zip", "pdf"].includes(fileExtension)) {
        downloadFileBlob(url, file.name);
      } else {
        this.imageUrl = url;
        this.imageVisible = true
      }
    },
    // 获取form详情
    getDetail () {
      API.getDetail({ id: this.formId }).then(res => {
        //获取详情后的逻辑处理 组装数据
        let detail = res.data.data;
        this.detailInfo = detail;
        // 基础信息组装
        this.baseInfo = {
          workCode: detail.workCode,
          organizationId: detail.organizationId,
          organizationName: detail.organizationName,
          sendDate: detail.sendDate,
          recordDate: detail.recordDate,
          unitName: detail.unitName,
          unitId: detail.unitId
        }
        // 施工内容组装
        this.workSafetyConstructionContentList = detail.workSafetyConstructionContentList
        if (!this.workSafetyConstructionContentList.length) {
          this.workSafetyConstructionContentList = [{}]
        }
        this.constructionForm.sfZjzm = detail.sfZjzm;
        this.$set(this.constructionForm, 'sfZjzm', detail.sfZjzm);
        //安全检查内容组装
        this.workSafetyCheckList = detail.workSafetyCheckList;
        this.$set(this, 'workSafetyCheckList', detail.workSafetyCheckList)
        if (!this.workSafetyCheckList.length) {
          this.workSafetyCheckList = [{}]
        }
        console.log(this.workSafetyCheckList, 'workSafetyCheckList');
        // 应急准备
        this.workSafetyContactsList = detail.workSafetyContactsList;
        if (!this.workSafetyContactsList.length) {
          this.workSafetyContactsList = [{}]
        }
        this.$set(this.meetForm, 'sfYjsb', detail.sfYjsb);
        this.$set(this.meetForm, 'sfXfsb', detail.sfXfsb);
        this.$set(this.protectionForm, 'sfHxpwr', detail.sfHxpwr);
        // try {
          // 危险确认内容组装
          this.dangerCheckList = JSON.parse(detail.wxqrCheckboxJson)
          // this.dangerCheckInput = JSON.parse(detail.wxqrInputJson);
          let dangerCheckInput = JSON.parse(detail.wxqrInputJson);
          for(let key in dangerCheckInput){
            // this.dangerCheckInput[key] = dangerCheckInput[key];
            this.$set(this.dangerCheckInput,key,dangerCheckInput[key])
          }
          // 专业许可证
          this.jobTicketList = JSON.parse(detail.workTicketJson)
          // 施工方案
          this.fileList = JSON.parse(detail.sgfaFile) 
          this.fileLists = JSON.parse(detail.sgfaFile)
          // 需要的防护设备  
          // this.protectMap = JSON.parse(detail.fhsbCheckboxJson)
          let protectMap = JSON.parse(detail.fhsbCheckboxJson);
          for(let key in protectMap){
            this.protectMap[key] = protectMap[key]
            this.$set(this.protectMap,key,protectMap[key]);
          }
          console.log(this.protectMap,protectMap,'this.protectMap')
          // this.protectInput = JSON.parse(detail.fhsbInputJson)
          let protectInput = JSON.parse(detail.fhsbInputJson);
          for(let key in protectInput){
            for(let ckey in protectInput[key]){
              this.$set(this.protectInput[key],ckey,protectInput[key][ckey])
            }
          }
          console.log(this.protectInput,'this.protectInput')
          // 环境保护
          // this.safework_environmentalMap = JSON.parse(detail.hjbhCheckboxJson)
          let safework_environmentalMap = JSON.parse(detail.hjbhCheckboxJson);
          for(let key in safework_environmentalMap){
            this.$set(this.safework_environmentalMap,key,safework_environmentalMap[key]);
          }
          console.log(this.safework_environmentalMap,safework_environmentalMap,'this.safework_environmentalMap')
          // this.safework_environmentalInput = JSON.parse(detail.hjbhInputJson)
          let safework_environmentalInput = JSON.parse(detail.hjbhInputJson)
          console.log(safework_environmentalInput,'safework_environmentalInput');
          for(let key in safework_environmentalInput){
              this.$set(this.safework_environmentalInput,key,safework_environmentalInput[key])
          }
        // } catch {
        // }
        this.getMeetList();
      })
    },
    // 保存草稿
    headSave () {
      clearTimeout(this.headSaveTimer);// 清除之前的定时器
      this.headSaveTimer = setTimeout(() => { // 延时触发操作
        let params = {
          ...this.baseInfo,
          ...this.meetForm,
          ...this.protectionForm,
          ...this.constructionForm,
          workSafetyConstructionContentList: this.workSafetyConstructionContentList,
          workSafetyCheckList: this.workSafetyCheckList,
          workSafetyContactsList: this.workSafetyContactsList,
          workTicketJson: JSON.stringify(this.jobTicketList),
          fhsbCheckboxJson: JSON.stringify(this.protectMap),
          fhsbInputJson: JSON.stringify(this.protectInput),
          hjbhCheckboxJson: JSON.stringify(this.safework_environmentalMap),
          hjbhInputJson: JSON.stringify(this.safework_environmentalInput),
          wxqrCheckboxJson: JSON.stringify(this.dangerCheckList),
          wxqrInputJson: JSON.stringify(this.dangerCheckInput)
        }
        if (!this.baseInfo.recordDate) {
          this.$message.warn('请选择工作纪录日期');
          return;
        }
        params.sgfaFile = JSON.stringify(this.fileLists)
        if (this.formId) {
          params.id = this.formId
        }
        API.addUpdate(params).then(res => {
          if (res.data.code == 200) {
            this.formId = res.data.data.id
            this.$message({
              message: "保存成功",
              type: "success",
            });
          }
        });
      }, 500);

    },
    // 提交
    headComplete () {
      clearTimeout(this.headCompleteTimer);// 清除之前的定时器
      this.headCompleteTimer = setTimeout(() => { // 延时触发操作
        let params = {
          ...this.baseInfo,
          ...this.meetForm,
          ...this.protectionForm,
          ...this.constructionForm,
          workSafetyConstructionContentList: this.workSafetyConstructionContentList,
          workSafetyCheckList: this.workSafetyCheckList,
          workSafetyContactsList: this.workSafetyContactsList,
          workTicketJson: JSON.stringify(this.workTicketJson),
          fhsbCheckboxJson: JSON.stringify(this.protectMap),
          fhsbInputJson: JSON.stringify(this.protectInput),
          hjbhCheckboxJson: JSON.stringify(this.safework_environmentalMap),
          hjbhInputJson: JSON.stringify(this.safework_environmentalInput),
          wxqrCheckboxJson: JSON.stringify(this.dangerCheckList),
          wxqrInputJson: JSON.stringify(this.dangerCheckInput)
        }
        if (!this.baseInfo.recordDate) {
          this.$message.warn('请选择工作纪录日期');
          return;
        }
        if (this.formId) {
          params.id = this.formId
          this.formProcess.id = this.formId
        }
        params.sgfaFile = JSON.stringify(this.fileLists)
        this.formInfo = params;
        API.addUpdate(params).then(res => {
          if (res.data.code == 200) {
            this.formId = res.data.data.id;
            this.formProcess.id = this.formId;
            this.handleStartProcess(this.formProcess, this.baseInfo.organizationId).then((processRes) => {
              params.processInstanceId = processRes.data.data;
              params.status = 2;
              API.addUpdate(params);
              this.$message.success(
                this.$t("cip.cmn.msg.success.operateSuccess")
              );
              this.$router.$avueRouter.closeTag();
              this.$router.push("/business/safeworkInspection/index");
              this.$loading().close();
            });
          }
        });
      })
    },
    // 班前会跳转
    toMeetList (row) {
      this.$router.push({
        path: "/beforeclass/edit",
        query: {
          id: row.id,
          type: "view",
        },
      });
    },
    // 引用
    tableQutoe (showName) {
      // this[tableName].push();
      this[showName] = true; // 唤醒施工内容引用弹窗
    },
    tableQutoeList () {
      this.$refs.listForm.init();
    },
    // 取消
    headCancel () {
      this.$router.$avueRouter.closeTag();
      this.$router.back();
    },
    // 新增行
    tableAdd (listName) {
      this[listName].push({});
    },
    // 删除行
    handleDelRow (index, row, listName) {
      this[listName].splice(index, 1);
    }
  }
}
</script>

<style lang="scss" scoped>
.link-list {
  background-color: #FFF;
  background-image: none;
  border-radius: 4px;
  min-height: 32px;
  border: 1px solid #DCDFE6;

  .link-item {
    padding: 10px;
    display: flex;
    align-items: center;

    .text {
      color: #409eff;
      text-decoration: underline;
      text-decoration-color: #409eff;
      margin-right: 10px;
    }

    .el-icon-remove {
      color: red;
      cursor: pointer;
    }
  }
}

.add-form {
  .block {
    padding: 20px;
    background: #fff;
  }
}

::v-deep.dangerCheckList {
  // padding-right: 100px;
  width: 70%;

  .el-textarea {
    display: inline-block;
    margin-left: 10px;
  }
}

.flex-checkList {
  display: flex;
}

.el-form-title {
  padding: 0 0 20px 0;
}

.el-col-checkList {
  margin-bottom: 10px !important;
}

.protection-checkList {
  width: 70%;
  display: flex;
  padding: 10px 0;

  .title {
    width: 60px;
    text-align: right;
    flex-shrink: 0;
    font-size: 14px;
    margin-right: 20px;
  }

  .el-checkbox-group {
    width: calc(100% - 60px);
  }

  .el-textarea {
    display: inline-block;
    margin-left: 10px;
  }
}

.waste-checkList {
  margin: 10px 0;
  width: 70%;
  display: flex;

  .title {
    width: 60px;
    text-align: right;
    flex-shrink: 0;
    font-size: 14px;
    margin-right: 20px;
  }

  .el-checkbox-group {
    width: calc(100% - 60px);
  }
}

::v-deep.protect-checkList {
  padding: 10px 0;
  display: flex;
  width: 70%;

  .title {
    width: 100px;
    text-align: right;
    flex-shrink: 0;
    margin-right: 20px;
  }

  .el-checkbox-group {
    width: calc(100% - 100px);

    .el-textarea {
      display: inline-block;
      margin-left: 10px;
    }
  }

}

.layout-title {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 10px 0;
}
</style>