<template>
  <div class="main-box riskIdentification">
    <el-tabs v-model="activeName" @tab-click="handleClick" type="border-card">
      <el-tab-pane
        :key="index"
        v-for="(item, index) in biz_sectior"
        :label="item.dictValue"
        :name="item.dictKey"
      ></el-tab-pane>
    </el-tabs>
    <div class="flex-container">
      <CommonTreeNew
        class="introduceTree"
        treeTitle="风险源配置"
        node-key="id"
        isLeafIcons
        risk
        :defaultExpandedKeys="defaultExpandedKeys"
        ref="commonTreeCore"
        :defaultProps="defaultProps"
        @getNodeClick="corePersonnelTreeNodeClick"
        @getTreeData="commonTreeData"
        :treeData="corePersonnelTreeData"
        :showCheckbox="false"
      />
      <div class="table-box">
        <head-layout
          :head-btn-options="[]"
          head-title="风险清单"
        ></head-layout>
        <grid-layout
          class="introduceTable"
          ref="gridLayOut"
          :table-options="corePersonnelTableOption"
          :data-total="page.total"
          :page="page"
          @page-current-change="onLoad"
          @page-size-change="onLoad"
          :table-data="tableData"
          :table-loading="TableLoading"
          @gird-handle-select-click="selectionChange"
        >
        </grid-layout>
      </div>
    </div>
    <div
      class="flex-container flex-jus-e footerBtn">
      <el-button
        :loading="loading"
        :disabled="loading"
        size="mini"
        @click="closeDialog"
      >取消</el-button
      >
      <el-button
        :loading="loading"
        :disabled="loading"
        size="mini"
        type="primary"
        @click="confirm"
      >确认</el-button
      >
    </div>
  </div>
</template>
<script>
import CommonTree from "@/views/components/com_tree/index.vue";
import Template from "@/views/message/template/list.vue";
import * as API from "@/api/safetyTtandard/riskIdentification";
import { exportBlob } from "@/api/common";
import {getToken} from '@/util/auth';
import { downloadXls } from "@/util/util";
import GridLayout from "@/views/components/layout/grid-layout";
import { dictionaryBiz } from "@/api/reportTasks";

export default {
  name: "riskIdentification",
  components: { Template, GridLayout, CommonTree },
  computed: {
    ids() {
      let ids = [];
      this.selectionList.forEach((ele) => {
        ids.push(ele.id);
      });
      return ids.join(",");
    },
    corePersonnelTableOption() {
      let column = [
        {
          label: "危险源描述",
          prop: "riskName",
          width: 300,
          align: "left",
          overHidden: true,
        },
        {
          label: "事故类型",
          prop: "eventName",
          align: "center",
          width: 150,
          overHidden: true,
        },
        {
          label: "现有控制措施",
          children: [
            {
              label: "工程技术",
              width: 300,
              align: "left",
              overHidden: true,
              prop: "treatEngineer",
            },
            {
              label: "管理措施",
              width: 300,
              align: "left",
              overHidden: true,
              prop: "treatManagment",
            },
            {
              label: "培训教育",
              width: 300,
              align: "left",
              overHidden: true,
              prop: "treatTraining",
            },
            {
              label: "个体防护",
              width: 300,
              align: "left",
              overHidden: true,
              prop: "treatProtection",
            },
            {
              label: "应急处置",
              width: 300,
              align: "left",
              overHidden: true,
              prop: "treatEmergency",
            },
          ],
        },
        {
          label: "风险评价LECD",
          children: [
            { label: "发生的可能性", width: 150, prop: "lecdL" },
            { label: "发生的频繁程度", width: 150, prop: "lecdE" },
            { label: "后果及严重性", width: 150, prop: "lecdC" },
          ],
        },
        {
          label: "风险等级",
          width: 150,
          prop: "riskLevel",
          align: "left",
          overHidden: true,
        },
        {
          label: "管控层级",
          prop: "controlLevel",
          align: "center",
          type: "select",
          props: {
            label: "dictValue",
            value: "dictKey",
          },
          dicUrl:
            "/api/sinoma-system/dict-biz/dictionary?code=RISK_CONTROL_LEVEL",
        },
      ];
      return {
        index: true,
        indexLabel: "序号",
        menuWidth: 100,
        column: column,
      };
    },
    jcxflOption() {
      return {
        span: 24,
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            disabled: this.jcxflForm.id ? true : false,
            display: this.jcxflForm.parentId != -1,
            span: 24,
            labelWidth: 200,
            label: "类型",
            prop: "isObj",
            type: "radio",
            dicData: [
              {
                label: "风险源分类",
                value: 0,
              },
              {
                label: "风险源",
                value: 1,
              },
            ],
          },
          {
            span: 24,
            props: this.defaultProps,
            dicData: this.treeData,
            type: "tree",
            labelWidth: 200,
            clearable: true,
            label: "上级风险源分类",
            prop: "parentId",
          },
          {
            span: 24,
            display: this.jcxflForm.isObj == 0,
            labelWidth: 200,
            label: "分类名称",
            prop: "sourceTypeName",
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: "blur",
              },
            ],
          },

          {
            span: 24,
            formSlot: true,
            display: this.jcxflForm.isObj == 1,
            labelWidth: 200,
            label: "分类名称",
            prop: "sourceName",
            rules: [
              {
                required: true,
                message: "请输入分类名称",
                trigger: "blur",
              },
            ],
          },
        ],
      };
    },
  },
  data() {
    return {
      activeName: "1",
      selectTree: undefined,
      jcxflForm: {
        parentId: "",
        isObj: 0,
      },
      jcxflModel: false,
      TableLoading: false,
      biz_sectior: [],
      tableData: [],
      treeData: [],
      query: {},
      page: {
        pageSize: 20,
        currentPage: 1,
        total: 0,
      },
      defaultProps: {
        children: "children",
        value: "id",
        label: "sourceTypeName",
      },
      corePersonnelTreeData: [],
      defaultExpandedKeys: [],
      excelBox: false,
      excelOption: {
        submitBtn: false,
        emptyBtn: false,
        column: [
          {
            label: this.$t("cip.plat.sys.user.field.excelFile"),
            prop: "excelFile",
            type: "upload",
            drag: true,
            loadText: this.$t("cip.plat.sys.user.field.waiting"),
            span: 24,
            propsHttp: {
              res: "data",
            },
            tip: this.$t("cip.plat.sys.user.field.format"),
            data: { typeId: "" },
            action: "/api/sinoma-hse-prj/strisklibrary/import1",
          },
          {
            label: this.$t("cip.plat.sys.user.field.excelTemplate"),
            prop: "excelTemplate",
            formslot: true,
            span: 24,
          },
        ],
      },
    };
  },
  async mounted() {
    // MD_SECTION
    this.biz_sectior = await dictionaryBiz("biz_sectior").then(
      (res) => res.data.data
    );
    this.typeTree();
  },
  methods: {
    closeDialog() {
      this.loading = false;
      this.$emit("closeDia");
    },
    //请选择人员
    confirm() {
      this.loading = true;
      if (this.closeCallBack) {
        this.$emit("select-data", this.selectionList,{
          loading: () => {
            this.loading = false;
          },
          done: () => {
            this.closeDialog();
          },
        });

      } else {
        this.$emit("select-data", this.selectionList);
        this.closeDialog();
      }
    },
    handleClick() {
      this.typeTree();
    },
    resetChange() {
      this.jcxflModel = false;
      this.jcxflForm = {};
    },
    handleSubmit(form, done) {
      let data = {
        ...form,
        parentId: this.jcxflForm.parentId || -1,
      };
      let URL = form.isObj == 0 ? API.submit : API.strisksourceSubmit;
      URL(data)
        .then((res) => {
          this.jcxflModel = false;
          this.$message.success(res.data.msg);
          this.typeTree();
        })
        .catch((err) => {
          done();
        });
    },

    getTreeAdd(row) {
      if (row.isSource)
        return this.$message.error("当前节点为风险源分类节点，不可新增节点");
      this.jcxflForm = {
        isObj: row.isObj || 0,
        parentId: row.id || -1,
        sourceTypeId: row.id || -1,
        sourceTypeCode: row.sourceTypeCode || -1,
        sectionId: this.activeName,
      };
      this.treeData = this.corePersonnelTreeData;
      this.jcxflModel = true;
    },
    getTreeDelete(data) {
      if (this.tableData.length != 0 || data.children.length != 0)
        return this.$message.error(
          "当前分类存在数据，或者下级，请先删除下级或者隐患描述数据"
        );
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", data.id);
          return !data.isSource
            ? API.remove(formData)
            : API.strisksourceRemove(formData);
        })
        .then(() => {
          this.$refs.commonTreeCore.nodeData = {};
          this.$message.success(this.$t("cip.cmn.msg.success.delSuccess"));
          this.typeTree();
        });
    },
    corePersonnelTreeNodeClick(row) {
      this.selectTree = row;
      this.onLoad(this.page);
    },
    getTreeEdit(row) {
      this.jcxflForm = {
        ...row,
        sourceTypeId: row.parentId,
        sourceName: row.isSource ? row.sourceTypeName : "",
        isObj: row.isSource ? 1 : 0,
      };
      const data = JSON.parse(JSON.stringify(this.corePersonnelTreeData));
      this.treeData = this.getParentData(data);
      this.jcxflModel = true;
    },
    getParentData(data, id) {
      data.forEach((val, index) => {
        if (val.id == this.jcxflForm.id) {
          data.splice(index, 1);
        } else if (val.children) {
          this.getParentData(val.children, this.jcxflForm.id);
        }
      });
      return data;
    },
    headImport() {
      this.findObject(this.excelOption.column, "excelFile").action = `/api/sinoma-hse-prj/strisklibrary/import1?sectionId=${this.activeName}`;
      this.excelBox = true;
    },
    uploadAfter(res, done, loading, column) {
      this.excelBox = false;
      this.page.currentPage = 1;
      this.onLoad(this.page);
      done();
    },
    handleTemplate() {
      exportBlob(
        `/api/sinoma-hse-prj/strisklibrary/exportTemplate?${
          this.website.tokenHeader
        }=${getToken()}`
      ).then((res) => {
        downloadXls(res.data, "风险清单模板.xlsx");
      });
    },
    headExport() {
      if (!this.selectTree) return this.$message.success("请选择隐患分类");
      exportBlob(
        `/api/sinoma-hse-prj//hdcheckindex/export?objectId=${this.selectTree.id}`
      ).then((res) => {
        downloadXls(res.data, this.selectTree.typeName);
      });
    },
    async addjcx(row = {}, type) {
      if (!this.selectTree.isSource)
        return this.$message.success("请选择风险源");

      this.$router.push({
        path: "/business/safetyTtandard/riskIdentification/add",
        query: {
          type: type || "add",
          id: row.id,
          sourceId: this.selectTree.id,
          sectionId: this.activeName,
        },
      });
    },
    headDel() {
      this.rowDel(this.selectionList);
    },
    rowDel(data) {
      this.selectionList = data;
      if (this.selectionList.length === 0) {
        this.$message.warning(this.$t("cip.cmn.msg.warning.selectWarning"));
        return;
      }
      let formData = new FormData();
      this.$confirm(this.$t("cip.cmn.msg.warning.delWarning"), {
        confirmButtonText: this.$t("cip.cmn.btn.defBtn"),
        cancelButtonText: this.$t("cip.cmn.btn.celBtn"),
        type: "warning",
      })
        .then(() => {
          formData.append("ids", this.ids);
          return API.sthdlistRemove(formData);
        })
        .then(() => {
          this.selectionList = [];
          this.$message.success(this.$t("cip.cmn.msg.success.operateSuccess"));
          this.onLoad(this.page);
        });
    },
    selectionChange(data) {
      this.selectionList = data;
    },
    delQueryKey(obj) {
      let newObj = { ...obj };
      Object.keys(newObj).forEach((item) => {
        if (this.isEmpty(newObj[item])) {
          delete newObj[item];
        }
      });
      return newObj;
    },
    isEmpty(obj) {
      if (typeof obj === "undefined" || obj === null || obj === "") {
        return true;
      } else {
        return false;
      }
    },
    // 搜索
    gridHeadSearch(searchForm) {
      const data = this.query;
      this.page.currentPage = 1;
      this.query = { ...data, ...searchForm };
      this.onLoad(this.page);
    },
    // 清空
    gridHeadEmpty(searchForm) {
      this.page.currentPage = 1;
      const data = this.query;
      this.query = { ...data, ...searchForm };
      this.onLoad(this.page);
    },
    onLoad(page, params = {}) {
      this.page = page || this.page;
      this.tableLoading = true;
      API.getList(this.page.currentPage, this.page.pageSize, {
        ...params,
        ...this.delQueryKey(this.query),
        sourceId: this.selectTree.id,
        sectionId: this.activeName,
      }).then((res) => {
        if (res.data.code == 200) {
          this.tableLoading = false;
          this.tableData = res.data.data.records;
          this.page.total = res.data.data.total;
          this.$refs.gridLayOut.page.total = res.data.data.total;
        }
      });
    },
    formatterData(jsonList) {
      return jsonList
        .filter((item) => item.isObj !== 1)
        .map((item) => {
          item = Object.assign({}, item);
          if (item.children) {
            item.children = this.formatterData(item.children);
          }
          return item;
        });
    },
    typeTree() {
      this.$refs.commonTreeCore.nodeData = {};
      this.selectTree = {};
      this.defaultExpandedKeys = [];
      API.getTree({
        sectionId: this.activeName,
      }).then((res) => {
        this.corePersonnelTreeData = res.data.data;
        if (res.data.data.length != 0) {
          this.selectTree = res.data.data[0] || {};
          if (!this.selectTree.id) return;
          this.defaultExpandedKeys = [this.selectTree.id];
          this.$nextTick(() => {
            this.$refs.commonTreeCore.$refs.commonTree.setCurrentKey(
              this.selectTree.id
            );
            this.$refs.commonTreeCore.nodeData = this.selectTree;
            this.corePersonnelTreeNodeClick(this.selectTree);
          });
        }
      });
    },
  },
};
</script>
<style scoped lang="scss">
.riskIdentification {
  background-color: #ffffff;
  .common_tree_handle .el-tree {
    height: calc(100vh - 278px) !important;
  }
  .table-box {
    width: calc(100% - 280px);
  }
}

::v-deep .introduceTable .avue-crud .el-table {
  height: calc(100vh - 330px) !important;
  max-height: calc(100vh - 330px) !important;
}

.introduceTree ::v-deep .common_tree_handle .el-tree {
  height: calc(100vh - 278px) !important;
}
::v-deep .el-tabs__header {
  margin-bottom: 0;
}
::v-deep .el-tabs--border-card {
  border-left: none;
  border-bottom: none;
}
::v-deep .el-tabs__content {
  padding: 0 !important;
}
</style>
