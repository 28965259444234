var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "add-form" },
    [
      _c("head-layout", {
        attrs: {
          "head-btn-options": _vm.headBtnOptions,
          "head-title": "基础信息",
        },
        on: {
          "head-save": _vm.headSave,
          "head-complete": _vm.headComplete,
          "head-cancel": _vm.headCancel,
        },
      }),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "el-form",
            {
              ref: "baseInfo",
              attrs: {
                model: _vm.baseInfo,
                "label-width": "120px",
                rules: _vm.baseRules,
                disabled: _vm.type == "view",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "编号", prop: "workCode" } },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.baseInfo.workCode,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseInfo, "workCode", $$v)
                              },
                              expression: "baseInfo.workCode",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "项目名称",
                            prop: "organizationName",
                          },
                        },
                        [
                          _c("el-input", {
                            attrs: { disabled: "" },
                            model: {
                              value: _vm.baseInfo.organizationName,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseInfo, "organizationName", $$v)
                              },
                              expression: "baseInfo.organizationName",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "发起日期", prop: "sendDate" } },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              disabled: "",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.baseInfo.sendDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseInfo, "sendDate", $$v)
                              },
                              expression: "baseInfo.sendDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "工作记录日期", prop: "recordDate" },
                        },
                        [
                          _c("el-date-picker", {
                            attrs: {
                              "value-format": "yyyy-MM-dd",
                              type: "date",
                              placeholder: "选择日期",
                            },
                            model: {
                              value: _vm.baseInfo.recordDate,
                              callback: function ($$v) {
                                _vm.$set(_vm.baseInfo, "recordDate", $$v)
                              },
                              expression: "baseInfo.recordDate",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("head-layout", {
        attrs: {
          "head-title": "施工内容",
          "head-btn-options":
            _vm.type != "view" ? _vm.securityContentOption : [],
        },
        on: {
          "head-add": function ($event) {
            return _vm.tableQutoeList()
          },
          "head-addLine": function ($event) {
            return _vm.tableAdd("workSafetyCheckList")
          },
        },
      }),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c("div", { staticClass: "el-form-title" }, [
            _vm._v("施工单位：" + _vm._s(_vm.baseInfo.unitName)),
          ]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.workSafetyConstructionContentList,
                border: "",
                size: "medium",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "center",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "address", align: "center", label: "工作地点" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入工作地点",
                            type: "textarea",
                            disabled: _vm.type == "view",
                            autosize: { minRows: 1, maxRows: 6 },
                            maxlength: "255",
                            "show-word-limit": "",
                          },
                          model: {
                            value: scope.row.address,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "address", $$v)
                            },
                            expression: "scope.row.address",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "content", align: "center", label: "工作内容" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入工作内容",
                            type: "textarea",
                            disabled: _vm.type == "view",
                            autosize: { minRows: 1, maxRows: 6 },
                            maxlength: "255",
                            "show-word-limit": "",
                          },
                          model: {
                            value: scope.row.content,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "content", $$v)
                            },
                            expression: "scope.row.content",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled: _vm.type == "view",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelRow(
                                  scope.$index,
                                  scope.row,
                                  "workSafetyConstructionContentList"
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("head-layout", {
        attrs: { "head-title": "危险确认", "head-btn-options": [] },
      }),
      _c("div", { staticClass: "block" }, [
        _c(
          "div",
          { staticClass: "dangerCheckList" },
          [
            _c(
              "el-checkbox-group",
              {
                attrs: { disabled: _vm.type == "view" },
                model: {
                  value: _vm.dangerCheckList,
                  callback: function ($$v) {
                    _vm.dangerCheckList = $$v
                  },
                  expression: "dangerCheckList",
                },
              },
              [
                _c(
                  "el-row",
                  _vm._l(_vm.safework_dangerType, function (item) {
                    return _c(
                      "el-col",
                      {
                        key: item.dictKey,
                        class: item.dictKey.includes("_input")
                          ? "el-col-checkList flex-checkList"
                          : "el-col-checkList",
                        attrs: {
                          span: item.dictKey.includes("_input") ? 24 : 6,
                        },
                      },
                      [
                        _c("el-checkbox", { attrs: { label: item.dictValue } }),
                        item.dictKey.includes("_input")
                          ? _c("el-input", {
                              attrs: {
                                type: "textarea",
                                disabled:
                                  !_vm.dangerCheckList.includes(
                                    item.dictValue
                                  ) || _vm.type == "view",
                                placeholder: "请输入" + item.dictValue,
                                autosize: { minRows: 1, maxRows: 6 },
                              },
                              model: {
                                value: _vm.dangerCheckInput[item.dictKey],
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.dangerCheckInput,
                                    item.dictKey,
                                    $$v
                                  )
                                },
                                expression: "dangerCheckInput[item.dictKey]",
                              },
                            })
                          : _vm._e(),
                      ],
                      1
                    )
                  }),
                  1
                ),
              ],
              1
            ),
          ],
          1
        ),
      ]),
      _c("head-layout", {
        attrs: { "head-title": "施工准备", "head-btn-options": [] },
      }),
      _c(
        "div",
        { staticClass: "block constructionForm" },
        [
          _c(
            "el-form",
            {
              ref: "constructionForm",
              attrs: {
                model: _vm.constructionForm,
                "label-width": "130px",
                disabled: _vm.type == "view",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 8 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: { label: "是否需要增加照明", prop: "sfZjzm" },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.constructionForm.sfZjzm,
                                callback: function ($$v) {
                                  _vm.$set(_vm.constructionForm, "sfZjzm", $$v)
                                },
                                expression: "constructionForm.sfZjzm",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.constructionForm.sfZjzm,
                                callback: function ($$v) {
                                  _vm.$set(_vm.constructionForm, "sfZjzm", $$v)
                                },
                                expression: "constructionForm.sfZjzm",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "layout-title" }, [_vm._v("安全检查")]),
          _vm.type != "view"
            ? _c("head-layout", {
                attrs: {
                  "head-title": "",
                  "head-btn-options": _vm.wtSecurityOption,
                },
                on: {
                  "head-add": _vm.tableQuote,
                  "head-addLine": function ($event) {
                    return _vm.tableAdd("workSafetyCheckList")
                  },
                },
              })
            : _vm._e(),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.workSafetyCheckList,
                border: "",
                size: "medium",
                disabled: _vm.type == "view",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "center",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "riskName",
                  align: "center",
                  label: "危险源描述",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入危险源描述",
                            type: "textarea",
                            disabled: _vm.type == "view",
                            autosize: { minRows: 1, maxRows: 6 },
                            "show-word-limit": "",
                          },
                          model: {
                            value: scope.row.riskName,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "riskName", $$v)
                            },
                            expression: "scope.row.riskName",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "treatEngineer",
                  align: "center",
                  label: "工程技术",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入工程技术",
                            type: "textarea",
                            disabled: _vm.type == "view",
                            autosize: { minRows: 1, maxRows: 6 },
                            "show-word-limit": "",
                          },
                          model: {
                            value: scope.row.treatEngineer,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "treatEngineer", $$v)
                            },
                            expression: "scope.row.treatEngineer",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: {
                  prop: "treatManagment",
                  align: "center",
                  label: "管控措施",
                },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入管控措施",
                            type: "textarea",
                            disabled: _vm.type == "view",
                            autosize: { minRows: 1, maxRows: 6 },
                            "show-word-limit": "",
                          },
                          model: {
                            value: scope.row.treatManagment,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "treatManagment", $$v)
                            },
                            expression: "scope.row.treatManagment",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-button",
                          {
                            attrs: {
                              type: "text",
                              disabled: _vm.type == "view",
                              size: "small",
                            },
                            on: {
                              click: function ($event) {
                                return _vm.handleDelRow(
                                  scope.$index,
                                  scope.row,
                                  "workSafetyCheckList"
                                )
                              },
                            },
                          },
                          [_vm._v("删除")]
                        ),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
          _c(
            "el-form",
            {
              ref: "constructionForm",
              staticStyle: { "margin-top": "10px" },
              attrs: { model: _vm.constructionForm, "label-width": "100px" },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 2 } },
                    [
                      _c(
                        "el-button",
                        {
                          staticStyle: { "margin-bottom": "10px" },
                          attrs: {
                            size: "small",
                            type: "primary",
                            disabled: _vm.type == "view",
                          },
                          on: { click: _vm.jobticketShow },
                        },
                        [_vm._v("关联专业许可证")]
                      ),
                    ],
                    1
                  ),
                  _c("el-col", { attrs: { span: 22 } }, [
                    _c(
                      "div",
                      { staticClass: "link-list" },
                      _vm._l(_vm.jobTicketList, function (item, index) {
                        return _c(
                          "div",
                          { key: item.id, staticClass: "link-item" },
                          [
                            _c(
                              "div",
                              {
                                staticClass: "text",
                                on: {
                                  click: function ($event) {
                                    return _vm.toJobTicketDetail(item)
                                  },
                                },
                              },
                              [
                                _vm._v(
                                  _vm._s(
                                    `${item.$ticketType}(${item.ticketCode})`
                                  )
                                ),
                              ]
                            ),
                            _vm.type != "view"
                              ? _c("i", {
                                  staticClass: "el-icon-remove",
                                  on: {
                                    click: function ($event) {
                                      return _vm.removeJobTicketList(index)
                                    },
                                  },
                                })
                              : _vm._e(),
                          ]
                        )
                      }),
                      0
                    ),
                  ]),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "施工方案", prop: "fileList" } },
                        [
                          _c(
                            "el-upload",
                            {
                              attrs: {
                                action:
                                  "/api/sinoma-resource/oss/endpoint/put-file-attach-name-biz/platform",
                                disabled: _vm.type == "view",
                                headers: _vm.headers,
                                "file-list": _vm.fileList,
                                file: "file",
                                "on-success": _vm.handleFileCoverSuccess,
                                "on-preview": _vm.handleFileCoverPreview,
                                "on-remove": _vm.handleRemove,
                                accept: ".doc,.docx,.pdf,.png,.jpg,.zip",
                                limit: 9,
                              },
                            },
                            [
                              _c(
                                "el-button",
                                {
                                  attrs: {
                                    size: "small",
                                    type: "primary",
                                    disabled: _vm.type == "view",
                                  },
                                },
                                [_vm._v("点击上传")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c("head-layout", {
        attrs: { "head-title": "需要的防护设备", "head-btn-options": [] },
      }),
      _c(
        "div",
        { staticClass: "block" },
        _vm._l(_vm.safework_protectType, function (item) {
          return _c(
            "div",
            { key: item.dictKey, staticClass: "protect-checkList" },
            [
              _c("div", { staticClass: "title" }, [
                _vm._v(_vm._s(item.dictValue + ":")),
              ]),
              _c(
                "el-checkbox-group",
                {
                  attrs: { disabled: _vm.type == "view" },
                  model: {
                    value: _vm.protectMap[item.dictKey],
                    callback: function ($$v) {
                      _vm.$set(_vm.protectMap, item.dictKey, $$v)
                    },
                    expression: "protectMap[item.dictKey]",
                  },
                },
                [
                  _c(
                    "el-row",
                    _vm._l(item.children, function (chiItem) {
                      return _c(
                        "el-col",
                        {
                          key: chiItem.dictKey,
                          class: chiItem.dictKey.includes("_input")
                            ? "el-col-checkList flex-checkList"
                            : "el-col-checkList",
                          attrs: {
                            span: chiItem.dictKey.includes("_input") ? 24 : 6,
                          },
                        },
                        [
                          _c("el-checkbox", {
                            attrs: { label: chiItem.dictValue },
                          }),
                          chiItem.dictKey.includes("_input")
                            ? _c("el-input", {
                                attrs: {
                                  type: "textarea",
                                  disabled:
                                    !_vm.protectMap[item.dictKey].includes(
                                      chiItem.dictValue
                                    ) || _vm.type == "view",
                                  placeholder: "请输入" + chiItem.dictValue,
                                  autosize: { minRows: 1, maxRows: 6 },
                                },
                                model: {
                                  value:
                                    _vm.protectInput[item.dictKey][
                                      chiItem.dictKey
                                    ],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.protectInput[item.dictKey],
                                      chiItem.dictKey,
                                      $$v
                                    )
                                  },
                                  expression:
                                    "protectInput[item.dictKey][chiItem.dictKey]",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    1
                  ),
                ],
                1
              ),
            ],
            1
          )
        }),
        0
      ),
      _c("head-layout", {
        attrs: { "head-title": "应急准备", "head-btn-options": [] },
      }),
      _c(
        "div",
        { staticClass: "block meetForm" },
        [
          _c(
            "el-form",
            {
              ref: "meetForm",
              attrs: {
                model: _vm.meetForm,
                "label-width": "240px",
                disabled: _vm.type == "view",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "有消防设备，状态如何，近期检查？",
                            prop: "sfXfsb",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.meetForm.sfXfsb,
                                callback: function ($$v) {
                                  _vm.$set(_vm.meetForm, "sfXfsb", $$v)
                                },
                                expression: "meetForm.sfXfsb",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.meetForm.sfXfsb,
                                callback: function ($$v) {
                                  _vm.$set(_vm.meetForm, "sfXfsb", $$v)
                                },
                                expression: "meetForm.sfXfsb",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    "el-col",
                    { attrs: { span: 24 } },
                    [
                      _c(
                        "el-form-item",
                        {
                          attrs: {
                            label: "有应急设备，状态如何，近期检查？",
                            prop: "sfYjsb",
                          },
                        },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.meetForm.sfYjsb,
                                callback: function ($$v) {
                                  _vm.$set(_vm.meetForm, "sfYjsb", $$v)
                                },
                                expression: "meetForm.sfYjsb",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.meetForm.sfYjsb,
                                callback: function ($$v) {
                                  _vm.$set(_vm.meetForm, "sfYjsb", $$v)
                                },
                                expression: "meetForm.sfYjsb",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c("div", { staticClass: "el-form-title" }, [
            _vm._v("\n      若有火情，事故或环境问题，拨打应急电话\n    "),
          ]),
          _c(
            "el-table",
            {
              staticStyle: { width: "100%" },
              attrs: {
                data: _vm.workSafetyContactsList,
                border: "",
                size: "medium",
                disabled: _vm.type == "view",
              },
            },
            [
              _c("el-table-column", {
                attrs: {
                  label: "序号",
                  type: "index",
                  align: "center",
                  width: "50",
                },
              }),
              _c("el-table-column", {
                attrs: { prop: "deptName", align: "center", label: "单位名称" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入单位名称",
                            disabled: _vm.type == "view",
                          },
                          model: {
                            value: scope.row.deptName,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "deptName", $$v)
                            },
                            expression: "scope.row.deptName",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "post", align: "center", label: "职位" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入职位",
                            disabled: _vm.type == "view",
                          },
                          model: {
                            value: scope.row.post,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "post", $$v)
                            },
                            expression: "scope.row.post",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "name", align: "center", label: "姓名" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c(
                          "el-input",
                          {
                            attrs: {
                              placeholder: "请输入姓名",
                              disabled: _vm.type == "view",
                            },
                            model: {
                              value: scope.row.name,
                              callback: function ($$v) {
                                _vm.$set(scope.row, "name", $$v)
                              },
                              expression: "scope.row.name",
                            },
                          },
                          [
                            _c("el-button", {
                              attrs: {
                                slot: "append",
                                disabled: _vm.type == "view",
                                icon: "el-icon-search",
                              },
                              on: {
                                click: function ($event) {
                                  return _vm.showMultipShow(
                                    scope.$index,
                                    "workSafetyContactsList"
                                  )
                                },
                              },
                              slot: "append",
                            }),
                          ],
                          1
                        ),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { prop: "phone", align: "center", label: "电话号码" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _c("el-input", {
                          attrs: {
                            placeholder: "请输入电话号码",
                            disabled: _vm.type == "view",
                          },
                          model: {
                            value: scope.row.phone,
                            callback: function ($$v) {
                              _vm.$set(scope.row, "phone", $$v)
                            },
                            expression: "scope.row.phone",
                          },
                        }),
                      ]
                    },
                  },
                ]),
              }),
              _c("el-table-column", {
                attrs: { fixed: "right", label: "操作", width: "100" },
                scopedSlots: _vm._u([
                  {
                    key: "default",
                    fn: function (scope) {
                      return [
                        _vm.workSafetyContactsList.length - 1 == scope.$index
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  size: "small",
                                  disabled: _vm.type == "view",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.tableAdd(
                                      "workSafetyContactsList"
                                    )
                                  },
                                },
                              },
                              [_vm._v("新增")]
                            )
                          : _vm._e(),
                        _vm.workSafetyContactsList.length > 1
                          ? _c(
                              "el-button",
                              {
                                attrs: {
                                  type: "text",
                                  disabled: _vm.type == "view",
                                  size: "small",
                                },
                                on: {
                                  click: function ($event) {
                                    return _vm.handleDelRow(
                                      scope.$index,
                                      scope.row,
                                      "workSafetyContactsList"
                                    )
                                  },
                                },
                              },
                              [_vm._v("删除")]
                            )
                          : _vm._e(),
                      ]
                    },
                  },
                ]),
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c("head-layout", {
        attrs: { "head-title": "环境保护", "head-btn-options": [] },
      }),
      _c(
        "div",
        { staticClass: "block" },
        [
          _c(
            "el-form",
            {
              ref: "protectionForm",
              attrs: {
                model: _vm.protectionForm,
                "label-width": "100px",
                disabled: _vm.type == "view",
              },
            },
            [
              _c(
                "el-row",
                [
                  _c(
                    "el-col",
                    { attrs: { span: 6 } },
                    [
                      _c(
                        "el-form-item",
                        { attrs: { label: "化学品污染？", prop: "radio" } },
                        [
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 1 },
                              model: {
                                value: _vm.protectionForm.sfHxpwr,
                                callback: function ($$v) {
                                  _vm.$set(_vm.protectionForm, "sfHxpwr", $$v)
                                },
                                expression: "protectionForm.sfHxpwr",
                              },
                            },
                            [_vm._v("是")]
                          ),
                          _c(
                            "el-radio",
                            {
                              attrs: { label: 0 },
                              model: {
                                value: _vm.protectionForm.sfHxpwr,
                                callback: function ($$v) {
                                  _vm.$set(_vm.protectionForm, "sfHxpwr", $$v)
                                },
                                expression: "protectionForm.sfHxpwr",
                              },
                            },
                            [_vm._v("否")]
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.safework_environmentalType, function (item) {
            return _c(
              "div",
              { key: item.dictKey, staticClass: "protection-checkList" },
              [
                _c("div", { staticClass: "title" }, [
                  _vm._v(_vm._s(item.dictValue + ":")),
                ]),
                _c(
                  "el-checkbox-group",
                  {
                    attrs: { disabled: _vm.type == "view" },
                    model: {
                      value: _vm.safework_environmentalMap[item.dictKey],
                      callback: function ($$v) {
                        _vm.$set(
                          _vm.safework_environmentalMap,
                          item.dictKey,
                          $$v
                        )
                      },
                      expression: "safework_environmentalMap[item.dictKey]",
                    },
                  },
                  [
                    _c(
                      "el-row",
                      _vm._l(item.children, function (chiItem) {
                        return _c(
                          "el-col",
                          {
                            key: chiItem.dictKey,
                            class: chiItem.dictKey.includes("_input")
                              ? "el-col-checkList flex-checkList"
                              : "el-col-checkList",
                            attrs: {
                              span: chiItem.dictKey.includes("_input") ? 24 : 6,
                            },
                          },
                          [
                            _c("el-checkbox", {
                              attrs: {
                                label: chiItem.dictValue,
                                value: chiItem.dictKey,
                              },
                            }),
                            chiItem.dictKey.includes("_input")
                              ? _c("el-input", {
                                  attrs: {
                                    type: "textarea",
                                    disabled:
                                      !_vm.safework_environmentalMap[
                                        item.dictKey
                                      ].includes(chiItem.dictValue) ||
                                      _vm.type == "view",
                                    placeholder: "请输入" + chiItem.dictValue,
                                    autosize: { minRows: 1, maxRows: 6 },
                                  },
                                  model: {
                                    value:
                                      _vm.safework_environmentalInput[
                                        item.dictKey
                                      ][chiItem.dictKey],
                                    callback: function ($$v) {
                                      _vm.$set(
                                        _vm.safework_environmentalInput[
                                          item.dictKey
                                        ],
                                        chiItem.dictKey,
                                        $$v
                                      )
                                    },
                                    expression:
                                      "safework_environmentalInput[item.dictKey][chiItem.dictKey]",
                                  },
                                })
                              : _vm._e(),
                          ],
                          1
                        )
                      }),
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
        ],
        2
      ),
      _c("head-layout", {
        attrs: { "head-title": "班前会", "head-btn-options": [] },
      }),
      _c("div", { staticClass: "block" }, [
        _c(
          "div",
          { staticClass: "link-list" },
          _vm._l(_vm.meetList, function (item, index) {
            return _c("div", { key: item.id, staticClass: "link-item" }, [
              _c(
                "div",
                {
                  staticClass: "text",
                  on: {
                    click: function ($event) {
                      return _vm.toMeetList(item)
                    },
                  },
                },
                [_vm._v(_vm._s(`${item.title}(${item.serialNumber})`))]
              ),
            ])
          }),
          0
        ),
      ]),
      _c(
        "el-dialog",
        {
          attrs: {
            visible: _vm.imageVisible,
            width: "60%",
            "append-to-body": true,
          },
          on: {
            "update:visible": function ($event) {
              _vm.imageVisible = $event
            },
          },
        },
        [
          _c("img", {
            staticStyle: {
              "margin-top": "20px",
              width: "100%",
              height: "auto",
              display: "block",
            },
            attrs: { src: _vm.imageUrl, alt: "" },
          }),
        ]
      ),
      _vm.MultipShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "人员选择",
                visible: _vm.MultipShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.MultipShow = $event
                },
              },
            },
            [
              _vm.MultipShow
                ? _c("ProjectSelectUser", {
                    attrs: {
                      treeParams: { parentId: _vm.baseInfo.organizationId },
                    },
                    on: {
                      closeDia: function ($event) {
                        _vm.MultipShow = false
                      },
                      "select-data": _vm.getUser,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.riskShow
        ? _c(
            "el-dialog",
            {
              attrs: {
                title: "风险辨识标准库",
                visible: _vm.riskShow,
                width: "80%",
                top: "8vh",
              },
              on: {
                "update:visible": function ($event) {
                  _vm.riskShow = $event
                },
              },
            },
            [
              _vm.riskShow
                ? _c("RiskIdentification", {
                    on: {
                      closeDia: function ($event) {
                        _vm.riskShow = false
                      },
                      "select-data": _vm.getRiskData,
                    },
                  })
                : _vm._e(),
            ],
            1
          )
        : _vm._e(),
      _vm.jobTicketDialogShow
        ? _c(
            "common-dialog",
            {
              attrs: { dialogTitle: "作业票清单", width: "60%" },
              on: {
                confirm: _vm.getJobTicketList,
                cancel: function ($event) {
                  _vm.jobTicketDialogShow = false
                },
              },
            },
            [
              _c("jobTicketDialog", {
                ref: "jobTicketDialog",
                attrs: { ticketCode: _vm.ticketCode, isMultiple: "" },
              }),
            ],
            1
          )
        : _vm._e(),
      _c("process-user-dialog", {
        ref: "processUser",
        on: { getUserSelection: _vm.handleUserSelection },
      }),
      _c("constructionList", {
        ref: "listForm",
        attrs: {
          prjId: _vm.baseInfo.organizationId,
          unitId: _vm.baseInfo.unitId,
        },
        on: { confirm: _vm.handleListForm },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }